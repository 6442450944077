



















































import { Component, Mixins, Ref } from "vue-property-decorator";
import VModal from "@/components/VModal.vue";
import ApiKeyMixin, { ApiKeyTags } from "@/mixins/http/ApiKeyMixin";
import ApiKey, { ApiKeyPayload } from "@/models/ApiKey";
import { easync } from "@/utils/http";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import { showErrorToast } from "@/utils/Toast";
import VIcon from "@/components/VIcon.vue";
import { sourceStore } from "../../store/typed";

@Component({
  components: {
    VModal,
    VIcon,
  },
})
export default class ApiKeyModal extends Mixins(ApiKeyMixin) {
  private action: "create" | "update" = "create";

  @Ref() readonly modal!: VModal;

  private payload = ApiKeyPayload.empty();
  private newScope: string | null = null;

  @RegisterHttp(ApiKeyTags.Create) readonly createRequest!: RequestData;

  get title() {
    switch (this.action) {
      case "create":
        return "Crea una nuova Api Key";
      case "update":
        return "Modifica l'Api Key";
    }

    return "-";
  }

  get source() {
    return sourceStore.currentSource;
  }

  addNewScope() {
    this.payload.scopes.push(this.newScope);
    this.newScope = null;
  }

  removeScope(index: number){
    this.payload.scopes.splice(index,1);
  }

  showCreate() {
    this.action = "create";
    this.payload = ApiKeyPayload.empty();
    this.payload.sourceId = this.source?.id;
    this.modal.show();
  }

  showUpdate(item: ApiKey | null = null) {
    this.action = "update";
    if (item) {
      this.payload = ApiKeyPayload.fromModel(item);
      this.payload.sourceId = this.source?.id;
    }

    this.modal.show();
  }

  onCancel() {
    this.modal.hide();
  }

  save() {
    switch (this.action) {
      case "create":
        this.create();
        break;
      case "update":
        this.update();
        break;
    }
  }

  async create() {
    this.payload.scopes = this.payload.scopes.filter((el: string) => el !== "");
    const [data, errors] = await easync(
      this.createApiKey(this.payload, ApiKeyTags.Create)
    );

    if (errors) {
      showErrorToast("Salvataggio Fallito");
      return;
    }

    this.$emit("created");
    this.modal.hide();
  }

  async update() {
    this.payload.scopes = this.payload.scopes.filter((el: string) => el !== "");
    const [data, errors] = await easync(
      this.updateApiKey(this.payload, ApiKeyTags.Update)
    );

    if (errors) {
      showErrorToast("Salvataggio Fallito");
      return;
    }

    this.$emit("updated");
    this.modal.hide();
  }

  onSourceSelected(id: string) {
    this.payload.sourceId = id;
  }

  addEmptyScope() {
    this.payload.scopes.push("");
  }

}
