var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"api-key-list px-4"},[_c('div',{staticClass:"d-flex justify-content-start mt-4"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){$event.stopPropagation();return _vm.onCreateNew($event)}}},[_vm._v(" Crea nuova Api Key ")])]),_c('data-table',{staticClass:"mt-4",attrs:{"columns":_vm.columns,"values":_vm.apiKeys,"loading":_vm.loading},scopedSlots:_vm._u([{key:"column_name",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value.name))])]}},{key:"column_key",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(value.key))])]}},{key:"column_scopes",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(value.scopes.join(','))+" ")])]}},{key:"actions",fn:function(ref){
var value = ref.value;
return [_c('button',{staticClass:"btn btn-link",on:{"click":function($event){$event.stopPropagation();return _vm.onSelected(value)}}},[_c('unicon',{staticClass:"text-primary",attrs:{"name":"edit"}})],1),_c('button',{staticClass:"btn btn-link ml-2",on:{"click":function($event){$event.stopPropagation();return _vm.deleteApiKey(value)}}},[_c('unicon',{attrs:{"fill":"red","name":"trash"}})],1)]}},{key:"loading",fn:function(){return [(_vm.loading)?_c('div',{staticClass:"page-content p-5 d-flex w-100 my-5"},[_c('fa-icon',{staticClass:"text-primary m-auto",attrs:{"icon":"circle-notch","size":"3x","spin":""}})],1):_vm._e()]},proxy:true},{key:"no-results-label",fn:function(){return [(_vm.apiKeys && _vm.apiKeys.length == 0 && !_vm.loading)?_c('empty-view',{attrs:{"primaryText":"Non c'è ancora nessun Api Key qui"}}):_vm._e()]},proxy:true}])}),(_vm.meta)?_c('div',{staticClass:"d-flex align-items-center w-100"},[_c('v-paginator',{staticClass:"mt-3",attrs:{"meta":_vm.meta,"loading":_vm.loading,"directionalArrows":false},on:{"change":function($event){return _vm.loadApiKeys($event)}}}),_c('div',{staticClass:"ml-3"},[(_vm.loading)?_c('fa-icon',{staticClass:"m-auto text-primary",attrs:{"icon":"spinner","size":"2x","spin":""}}):_vm._e()],1)],1):_vm._e(),_c('api-key-modal',{ref:"apiKeyModal",on:{"created":_vm.loadApiKeys,"updated":_vm.loadApiKeys}}),_c('confirm-modal',{ref:"confirmModal",attrs:{"title":"Eliminazione Api Key"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }