
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';
const BASE_COUPON_URL = BASE_API_URL+"api_keys"

export enum ApiKeyTags {
  Index="ApiKeyIndex",
  Create="ApiKeyCreate",
  Update="ApiKeyUpdate",
  Destroy="ApiKeyDestroy",
}

@Component
export default class ApiKey extends Vue {

  getApiKeys(data: any, tag: string) {
    return this.$httpGetQuery( BASE_COUPON_URL, tag,data);
  }

  getApiKey(id: number, tag: string) {
    return this.$httpGet( BASE_COUPON_URL+"/"+id, tag);
  }

  destroyApiKey(id: string|number, tag: string) {
    return this.$httpDelete( BASE_COUPON_URL+"/"+id, tag);
  }

  createApiKey(data: any, tag: string) {
    return this.$httpPost( BASE_COUPON_URL, tag, data);
  }

  updateApiKey(data: any, tag: string) {
     return this.$httpPut( BASE_COUPON_URL+"/"+data.id, tag, data);
  }
}
