








































































































import { Component, Mixins, Ref } from 'vue-property-decorator';

import ApiKeyMixin, { ApiKeyTags } from '@/mixins/http/ApiKeyMixin';
import { ApiKey } from '@/models';
import { easync } from '@/utils/http';
import { showErrorToast, showToast } from '@/utils/Toast';
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import { RegisterHttp } from '@/utils/Decorators';
import { RequestData } from '@/store/types/RequestStore';
import ApiKeyModal from '@/components/ApiKey/Modal.vue';
import VPaginator from '@/components/VPaginator.vue';
import VIcon from '@/components/VIcon.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import { sourceStore } from '../../store/typed';

@Component({
  components: { DataTable, ApiKeyModal, VPaginator, VIcon, ConfirmModal }
})
export default class ApiKeyList extends Mixins(ApiKeyMixin) {
  private apiKeys: ApiKey[] = [];

  @RegisterHttp(ApiKeyTags.Index) readonly apiKeysIndex!: RequestData;

  @Ref() readonly apiKeyModal!: ApiKeyModal;
  @Ref() readonly confirmModal!: ConfirmModal;

  created() {
    this.loadApiKeys();
  }

  get source() {
    return sourceStore.currentSource;
  }

  async loadApiKeys(page = 1) {
    const payload = {
      page: page,
      filter: {source: this.source?.id}
    }

    const [data] = await easync(this.getApiKeys(payload, ApiKeyTags.Index));

    if (data) {
      this.apiKeys = data;
    } else {
      this.apiKeys = [];
      showErrorToast('Si è verificato un errore nel caricare le api key');
    }
  }

  get columns() {
    return [
      { name: "key", label: "Key" },
      { name: "name", label: "Nome" },
      { name: "scopes", label: "Scopes" },
    ];
  }

  get loading() {
    return this.apiKeysIndex?.loading;
  }

  get meta() {
    return this.apiKeysIndex?.meta;
  }

  onSelected(apiKey: ApiKey) {
    this.apiKeyModal.showUpdate(apiKey);
  }

  onCreateNew() {
    this.apiKeyModal.showCreate();
  }

  async deleteApiKey(apiKey: ApiKey) {
    const confirmed = await
      (this.confirmModal as any).confirm("Sei sicuro di voler eliminare questo Api Key?")


    if (!confirmed) {
      return;
    }

    const [data, errors] = await easync(this.destroyApiKey(apiKey.id, ApiKeyTags.Destroy));

    if (errors) {
      showErrorToast('Si è verificato un errore durante l\'eliminazione');
      return;
    }

    showToast('Api Key eliminata con successo!');
    this.loadApiKeys();
  }
}
